/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Boilerplate = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.navbar-close').click(function(){
          $("#js-bootstrap-offcanvas").trigger("offcanvas.toggle");
        });

        $(window).scroll(function() {
          if ($(this).scrollTop() > 0) {
            $('.sub-footer').animate({opacity: 'show'}, 'slow');
          }
          else {
            $('.sub-footer').animate({opacity: 'hide'}, 'slow');
          }
        });

        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });
        
        var header = $(".primary-header");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 100) {
                header.addClass("fixed");
            } else {
                header.removeClass("fixed");
            }
        });


        // Open external links and documents in new window
        $(document).ready(function(){
          $('a[href^="http://"], a[href^="/documents/"]').attr('target', '_blank');

          $('a[href^="/documents/"]').each( function( index, element ){
              var src = $('a[href^="/documents/"]').attr('href');
              var cut = src.split('/')[2] + "/";
              $(this).attr('href', "https://s3.amazonaws.com/ivc-prod" + src.replace(cut, ""));
          });
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.carousel').carousel({
            pause: "false",
            interval: 1000 * 10
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'content_form_page': {
      init: function(){

        $(document).ready(function(){
          var thisform = $(".content-form");

          if($(window).width() > 767 ) {
            thisform.stick_in_parent({
              'offset_top': 100
            });
          }

          thisform
            .on('sticky_kit:bottom', function(e) {
                $(this).parent().css('position', 'static');
            })
            .on('sticky_kit:unbottom', function(e) {
                $(this).parent().css('position', 'relative');
            })

        });



      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Boilerplate;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
